import API from '@/api/API'
import { documentsTemplate, documentTemplate } from './template'
import { EDocumentStatus } from '@/dict/enum'
import http from '@/api/http'
import { AxiosResponse } from 'axios'
import { ElNotification } from 'element-plus'
import controllerResponse from '@/api/controllerResponse'

export default {
  /**
   * @description
   */
  async DOCUMENTS_ITEMS ({ dispatch, commit, getters }: any) {
    const query = getters.GET_DOCUMENTS__PANEL
    const method = API.documents(query)
    const response = await dispatch('getData', { method })
    const clients = response?.data || []
    const total = response?.meta?.total || 0
    commit('MUT_DOCUMENTS', { documents: documentsTemplate(clients), total })
    return response
  },
  async DOCUMENT_ITEM ({ dispatch, commit }: any, documentId:number) {
    const query = '?include=customer,metadata,ocr'
    const method = API.document(documentId, query)
    const response = await dispatch('getData', { method })
    const data = response?.data || []
    commit('MUT_DOCUMENT', documentTemplate(data))
    commit('MUT_CLIENT_STATE', { client: data.customer })
    return response
  },
  async DOCUMENT_UPLOAD (store: any, params:any) {
    const data = new FormData()
    data.append('customerId', params.customerId)
    data.append('document', params.document)

    if (params.relationshipDocument) {
      data.append('relationshipDocument', params.relationshipDocument)
    }
    if (params.addressId) {
      data.append('addressId', params.addressId)
    }
    if (params.representativeId) {
      data.append('representativeId', params.representativeId)
    }
    data.append('confirmationType', params.confirmationType)
    data.append('documentType', params.documentType)
    if (params.relationshipDocument) {
      data.append('relationshipDocument', params.relationshipDocument)
    }
    if (params.additionalDocument) {
      data.append('additionalDocument', params.additionalDocument)
    }
    if (params.relationshipDocumentType) {
      data.append('relationshipDocumentType', params.relationshipDocumentType)
    }
    const method = API.documentUpload
    const headers = { 'content-type': 'multipart/form-data' }
    const response = await http
      .post(method, data, { headers })
      .then((res: AxiosResponse) => {
        ElNotification({
          type: 'success',
          message: 'SAVE',
          duration: 2000
        })
        return controllerResponse(method, res)
      })
    return response?.data || []
  },
  async DOCUMENT_ASSIGN ({ dispatch }:any, { id, userId }:any) {
    const method = API.documentAssign(id)
    const params = {
      userId
    }
    return await dispatch('setData', { method, params })
  },
  async DOCUMENT_FILE ({ dispatch }: any, id:number) {
    const method = API.documentLoad(id)
    return await dispatch('getData', { method })
  },
  async DOCUMENT_LAST ({ dispatch, commit }: any, val:any) {
    const query = new URLSearchParams(val).toString()
    const method = API.documentLast(query)
    const response = await dispatch('getData', { method })
    const data = response.data || []
    commit('MUT_DOCUMENTS_STATE', { documentsLast: data })
    if (val.status === EDocumentStatus.ACTIVE) {
      commit('MUT_DOCUMENTS_STATE', { documentsLastApprove: data })
    }
    if (val.status === EDocumentStatus.CANCELED) {
      commit('MUT_DOCUMENTS_STATE', { documentsLastReject: data })
    }
    return data
  },
  async DOCUMENT_EDIT_STATUS ({ dispatch }: any, { id, rejectReasonId, type }:any) {
    let params = {}
    if (type === 'reject') {
      params = {
        rejectReasonId
      }
    }
    const method = API.documentStatus(id, type)
    return await dispatch('setData', { method, params })
  },
  SET_DOCUMENTS_PANEL ({ commit }:any, data:any) {
    commit('MUT_DOCUMENTS_PANEL', data)
  },
  SET_DOCUMENTS ({ commit }:any, data:any) {
    commit('MUT_DOCUMENTS', data)
  },
  CLEAR_DOCUMENTS_PANEL ({ commit }: any) {
    const panel = {
      status: '',
      riskScore: [0, 0],
      country: '',
      dateFrom: '',
      dateTo: '',
      search: '',
      assignedTo: '',
      size: 20,
      page: 1,
    }
    commit('MUT_DOCUMENTS_PANEL', panel)
  }
}
