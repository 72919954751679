import API from '@/api/API'

export default {
  async DICT_CATEGORIES ({ dispatch, commit, state }:any) {
    if (state?.categories) return state?.categories
    const method = API.dictCategories
    const response = await dispatch('getData', { method })
    const categories = response?.data || []
    commit('SET_DICT_STATE', { categories })
    return categories
  },
  async DICT_REJECT ({ dispatch }:any, type:string) {
    const method = API.documentReject(type)
    const response = await dispatch('getData', { method })
    return response?.data || []
  },
  async DICT_DOC_TYPES ({ dispatch, commit, state }:any) {
    if (state?.docTypes) return state?.docTypes
    const method = API.dictDocTypes
    const response = await dispatch('getData', { method })
    const docTypes = response?.data || []
    commit('SET_DICT_STATE', { docTypes })
    return docTypes
  },
  async DICT_FEE_GROUP ({ dispatch, commit, state }:any) {
    if (state?.feeGroup) return state?.feeGroup
    const method = API.dictFee
    const response = await dispatch('getData', { method })
    const feeGroup = response?.data || []
    commit('SET_DICT_STATE', { feeGroup })
    return feeGroup
  },
}
