import { createStore } from 'vuex'
import global from './modules/global'
import dict from './modules/dict'
import clients from '../components/Admin/Clients/store'
import documents from '../components/Admin/Documents/store'
import tickets from '../components/Admin/Tickets/store'
import settings from '../components/Admin/Settings/store'

export default createStore({
  modules: {
    global,
    clients,
    documents,
    tickets,
    dict,
    settings,
  }
})
