export default {
  SET_SETTINGS_STATE: (state:any, data:any) => {
    for (const item in data) {
      state[item] = data[item]
    }
  },
  MUT_PANEL_BLACK_LIST: (state: any, data: any) => {
    state.panelBlackList = data
  },
}
