import API from '@/api/API'
import {
  clientsTemplate,
  clientTemplate,
  clientAddressTemplate,
  clientDocumentTemplate,
  clientRepresentativeTemplate,
  clientPhoneTemplate
} from './template'
import {EKycStatus} from "@/dict/enum";

export default {
  async CLIENTS_ITEMS ({ dispatch, commit, getters }:any) {
    const query = getters.GET_CLIENTS__PANEL
    const method = API.clients(query)
    const response = await dispatch('getData', { method })
    const clients = response?.data || []
    const total = response?.meta?.total || 0
    commit('MUT_CLIENTS', { clients: clientsTemplate(clients), total })
    return response
  },
  async CLIENTS_ITEM ({ dispatch, commit }:any, id: number) {
    const method = API.client(id)
    const response = await dispatch('getData', { method })
    const data = response?.data || {}
    const client = clientTemplate(data)
    commit('MUT_CLIENT_STATE', { client })
    return client
  },
  // async _CLIENTS_ITEM ({ dispatch }:any, id: number) {
  //   const url = API.customerInfo_API(id)
  //   return await dispatch('FETCH_API_GET', { url, method: 'GET' }) || []
  // },
  /**
   * @description Customer Update
   */
  async CLIENTS_ITEM_UPDATE ({ dispatch }:any, params:any) {
    const { customerId } = params
    const method = API.client(customerId)
    return await dispatch('putData', { method, params })
  },
  async CLIENT_ADDRESS ({ dispatch, commit }:any, id: number) {
    const query = '?include=documents'
    const method = API.clientAddress(id, query)
    const response = await dispatch('getData', { method })
    const data = response?.data || {}
    const result = clientAddressTemplate(data)
    commit('MUT_CLIENT_ADDRESS', result)
    return result
  },
  async CLIENT_DOCUMENTS ({ dispatch, commit }:any, id: number) {
    const method = API.clientDocuments(id)
    const response = await dispatch('getData', { method })
    const address = response?.data || {}
    const documents = clientDocumentTemplate(address)
    commit('MUT_CLIENT_DOCUMENT', documents)
    return documents
  },
  async CLIENT_DOWNLOAD_DOCUMENTS_ARCHIVE({ dispatch }:any, id: number) {
    const method = API.clientDownloadDocumentsArchive(id)
    return await dispatch('getData', { method, responseType: 'blob' })
  },
  async CLIENT_PHONE ({ dispatch, commit }:any, id: number) {
    const method = API.clientPhone(id)
    const response = await dispatch('getData', { method })
    const data = response?.data || {}
    const result = clientPhoneTemplate(data)
    commit('MUT_CLIENT_PHONE', result)
    return result
  },
  async CUSTOMER_ASSIGN ({ dispatch }:any, { id, userId }:any) {
    const method = API.clientAssign(id)
    const params = {
      userId
    }
    return await dispatch('setData', { method, params })
  },
  async CUSTOMER_CLOSE ({ dispatch }:any, { id, params }:any) {
    const method = API.clientClose(id)
    const response = await dispatch('setData', { method, params })
    await dispatch('CLIENTS_ITEM', id)
    return response
  },
  async CUSTOMER_VERIFY ({ dispatch }:any, id:number) {
    const method = API.clientVerify(id)
    const response = await dispatch('getData', { method })
    await dispatch('CLIENTS_ITEM', id)
    return response
  },
  async CUSTOMER_UNLIMIT ({ dispatch }:any, { id, resolution }:any) {
    const method = API.clientUnlimit(id)
    const params = {
      resolution
    }
    const response = await dispatch('setData', { method, params })
    dispatch('CLIENTS_ITEM', id)
    return response
  },
  async CUSTOMER_LIMIT ({ dispatch, commit }:any, id:number) {
    const method = API.clientLimit(id)
    const response = await dispatch('getData', { method })
    const limit = response?.data || {}
    commit('MUT_CLIENT_STATE', { limit })
    return limit
  },
  async CUSTOMER_LIMIT_UPDATE ({ dispatch }:any, { id, params }:any) {
    const method = API.clientLimit(id)
    const response = await dispatch('setData', { method, params })
    dispatch('CLIENTS_ITEM', id)
    dispatch('CUSTOMER_LIMIT', id)
    return response
  },
  async CUSTOMER_RISK ({ dispatch, commit }:any, id:number) {
    const method = API.clientRisk(id)
    const response = await dispatch('getData', { method })
    const risk = response?.data || []
    commit('MUT_CLIENTS', { risk })
    return risk
  },
  async CUSTOMER_RISK_UPDATE ({ dispatch, commit }:any, params:any) {
    const method = API.clientRiskUpdate(params.id)
    const response = await dispatch('putData', { method, params })
    const risk = response?.data || []
    commit('MUT_CLIENTS', { risk })
    return response
  },
  async CUSTOMER_VERIFICATION_LIST ({ dispatch }:any, params:any) {
    const method = API.verificationRequestList(params.customerId)
    const response = await dispatch('getData', { method })
    return response?.data || []
  },
  async VERIFICATION_UPDATE ({ dispatch }:any, params:any) {
    const method = API.verificationRequestUpdate(params.id)
    const response = await dispatch('putData', { method, params })
    dispatch('CUSTOMER_VERIFICATION_LIST', { customerId: params.customerId })
    return response?.data || []
  },
  async VERIFICATION_COMPLETE ({ dispatch }:any, params:any) {
    const method = API.verificationRequestComplete(params.id)
    const response = await dispatch('setData', { method })
    dispatch('CUSTOMER_VERIFICATION_LIST', { customerId: params.customerId })
    return response?.data || []
  },
  // async CLIENT_EMAIL ({ dispatch }:any, id: number) {
  //   const method = API.clientEmail(id)
  //   const response = await dispatch('getData', { method })
  //   const data = response?.data || {}
  //   dispatch('SET_CLIENT_EMAIL', clientEmailTemplate(data))
  //   return response
  // },
  async CLIENT_REPRESENTATIVE ({ dispatch, commit }:any, id: number) {
    const method = API.clientRepresentative(id)
    const response = await dispatch('getData', { method })
    const data = response?.data || []
    const representative = clientRepresentativeTemplate(data)
    commit('MUT_CLIENT_STATE', { representative })
    commit('MUT_CLIENT_STATE', { representatives: data })
    return representative
  },
  // KYC
  async CLIENT_KYC_REQUEST ({ dispatch, commit }:any, id: number) {
    const method = API.kycRequest(id)
    const response = await dispatch('getData', { method })
    const kycItem = response?.data || {}
    commit('MUT_CLIENT_STATE', { kycItem })
    return kycItem
  },
  async CLIENT_KYC_REQUEST_ADD ({ dispatch }:any, params: any) {
    const method = API.kycRequest(params.id)
    const response = await dispatch('setData', { method, params })
    dispatch('CLIENT_KYC_REQUEST', params.id)
    return response?.data || {}
  },
  async CLIENT_KYC_REQUEST_DEL ({ dispatch }:any, id: number) {
    const method = API.kycRequest(id)
    const response = await dispatch('setDelete', { method })
    dispatch('CLIENT_KYC_REQUEST', id)
    return response?.data || {}
  },
  async CLIENT_SANCTION ({ dispatch }: any, customerId: any) {
    const url = API.customerSanctions_API(customerId)
    return await dispatch('FETCH_API_GET', { url, method: 'GET' }) || []
  },
  // END KYC
  SET_CLIENTS_PANEL ({ commit }:any, data:any) {
    commit('MUT_PANEL', data)
  },
  SET_CLIENTS ({ commit }:any, data:any) {
    commit('MUT_CLIENTS', data)
  },
  RESET_CLIENT_ITEM ({ commit }:any) {
    commit('MUT_CLIENT_ITEM')
  },
  SET_CLIENT_STATE ({ commit }:any, data:any) {
    commit('MUT_CLIENT_STATE', { [data.key]: data.val })
  },
  SET_CLIENT ({ commit }:any, data:any) {
    commit('MUT_CLIENT', data)
  },
  SET_CLIENT_ADDRESS ({ commit }:any, data:any) {
    commit('MUT_CLIENT_ADDRESS', data)
  },
  SET_CLIENT_PHONE ({ commit }:any, data:any) {
    commit('MUT_CLIENT_PHONE', data)
  },
  CLEAR_PANEL ({ commit }: any) {
    const panel = {
      type: '',
      status: 'ACTIVE',
      kycStatus: EKycStatus.VERIFIED,
      riskScore: [0, 0],
      country: '',
      dateFrom: '',
      dateTo: '',
      search: '',
      assignedTo: '',
      awaitingApproval: '',
      size: 20,
      page: 1,
    }
    commit('MUT_PANEL', panel)
  }
}
