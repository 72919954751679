export default {
  MUT_DOCUMENTS_STATE: (state:any, data:any) => {
    for (const item in data) {
      state[item] = data[item]
    }
  },
  MUT_DOCUMENTS: (state: any, data: any) => {
    state.documents = data.documents
    state.total = data.total
  },
  MUT_DOCUMENT: (state: any, data: any) => {
    state.document = data
  },
  MUT_DOCUMENTS_PANEL: (state: any, data: any) => {
    state.panel = data
  }
}
