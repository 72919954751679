export default {
  GET_TICKET: ({ ticket }:any) => (ticket),
  GET_TICKET_LIST: ({ tickets }:any) => (tickets),
  GET_TICKET_TEAM_HELPDESK: ({ team }:any) => (team),
  GET_TICKET_TEAM_HELPDESK_TEMP: ({ temp }:any) => (temp),
  GET_TICKET_ADDRESS: ({ addressReply }:any) => (addressReply),
  GET_TICKET_CONTENT: ({ ticketContent }:any) => (ticketContent),
  // new
  GET_TICKET_CONTACT: ({ contact }:any) => (contact),
}
