export default {
  MUT_CLIENT_STATE: (state:any, data:any) => {
    for (const item in data) {
      state[item] = data[item]
    }
  },
  MUT_CLIENTS: (state: any, data: any) => {
    state.clients = data.clients
    state.total = data.total
  },
  MUT_PANEL: (state: any, data: any) => {
    state.panel = data
  },
  MUT_CLIENT: (state: any, data: any) => {
    state.client = data
  },
  MUT_CLIENT_ADDRESS: (state: any, data: any) => {
    state.address = data
  },
  MUT_CLIENT_PHONE: (state: any, data: any) => {
    state.phones = data
  },
  MUT_CLIENT_REPRESENTATIVE: (state: any, data: any) => {
    state.documents = data
  },
  MUT_CLIENT_DOCUMENT: (state: any, data: any) => {
    state.documents = data
  },
  MUT_CLIENT_ITEM: (state: any) => {
    state.documents = []
    state.representatives = []
    state.representative = {}
    state.client = {}
    state.address = []
    state.phones = []
    state.email = []
  }
}
