import { ECustomerPhoneStatus, EDocumentStatus } from '@/dict/enum'

export default {
  GET_CLIENTS__PANEL: (state: any) => {
    const { status, riskScore, country, dateFrom, dateTo, query, size, page, type, kycStatus, assignedTo, awaitingApproval } = state.panel
    const PAGE = page ? `page=${page}` : ''
    const TYPE = type ? `&type=${type}` : ''
    const PAGE_SIZE = size ? `&size=${size}` : ''
    const STATUS = status ? `&status=${status}` : ''
    console.log(state.panel)
    const KYC = kycStatus ? `&kycStatus=${kycStatus}` : ''
    const riskScoreFrom = riskScore[0] ? `&riskScoreFrom=${riskScore[0]}` : ''
    const RISK_TO = riskScore[1] ? `${riskScoreFrom}&riskScoreTo=${riskScore[1]}` : ''
    const SEARCH = query ? `&query=${query}` : ''
    const DATE_FROM = dateFrom ? `&dateFrom=${dateFrom}` : ''
    const DATE_TO = dateTo ? `&dateTo=${dateTo}` : ''
    const COUNTRY = country.length ? `&country=${country}` : ''
    const ASSIGNED = assignedTo ? `&assignedTo=${assignedTo}` : ''
    const AWAITING_APPROVAL = awaitingApproval ? `&awaitingApproval=${awaitingApproval}` : ''
    return `${PAGE}${PAGE_SIZE}${TYPE}${STATUS}${RISK_TO}${SEARCH}${DATE_FROM}${DATE_TO}${COUNTRY}${KYC}${ASSIGNED}${AWAITING_APPROVAL}`
  },
  GET_CLIENTS: ({ clients }:any) => (clients),
  GET_CLIENTS_PANEL: ({ panel }:any) => (panel),
  GET_CLIENTS_TOTAL: ({ total }:any) => (total),
  //
  GET_CLIENT: (state:any) => {
    const { client, phones } = state
    let phone
    if (Array.isArray(phones) && phones.length === 1) {
      phone = phones[0]
    } else if (Array.isArray(phones) && phones.length > 0) {
      phone = phones?.find((e:any) => e.status === ECustomerPhoneStatus.PRIMARY) || undefined
    }
    client.phone = phone
    return client
  },
  GET_CLIENT_ID: ({ client }:any) => (client?.id),
  GET_CLIENT_DOCUMENTS: ({ documents }:any) => (documents || []),
  GET_CLIENT_REPRESENTATIVE: ({ representative }:any) => (representative),
  GET_CLIENT_REPRESENTATIVES: ({ representatives }:any) => (representatives),
  GET_CLIENT_ADDRESS: ({ address }:any) => (address),
  GET_CLIENT_PHONES: ({ phones }:any) => (phones),
  GET_CLIENT_LIMIT: ({ limit }:any) => (limit),
  GET_CLIENT_KYC: ({ kycItem }:any) => (kycItem),
  GET_ADDRESS_ACTIVE: ({ address }:any) => {
    if (Array.isArray(address) && address.length > 0) {
      return address?.find((e:any) => e.status === EDocumentStatus.ACTIVE) || []
    }
    return {}
  },
  GET_PHONE_ACTIVE: ({ phones }:any) => {
    if (Array.isArray(phones) && phones.length === 1) {
      return phones[0]
    } else if (Array.isArray(phones) && phones.length > 0) {
      return phones?.find((e:any) => e.status === ECustomerPhoneStatus.PRIMARY) || undefined
    }
    return undefined
  },
}
