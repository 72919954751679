export default {
  SET_TICKET_STATE: (state:any, data:any) => {
    for (const item in data) {
      state[item] = data[item]
    }
  },
  DESTROY_TICKET_STATE: (state:any) => {
    state.tickets = []
    state.ticket = {}
    state.team = []
    state.temp = []
    state.contact = []
  }
}
