import { IDocumentData } from '@/dict/interface'

export default {
  panel: {
    status: '',
    country: '',
    dateFrom: '',
    dateTo: '',
    query: '',
    assignedTo: '',
    size: 20,
    page: 1,
  },
  documents: [] as IDocumentData[],
  documentsLast: [] as IDocumentData[],
  documentsLastApprove: [] as IDocumentData[],
  documentsLastReject: [] as IDocumentData[],
  document: {},
  total: 0,
}
