export default {
  loginInfo: {},
  tickets: [],
  ticket: {},
  ticketContent: {},
  team: [],
  temp: [],
  addressReply: [],
  contact: [],
}
