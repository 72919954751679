export default {
  blackList: [],
  blackListTotal: 0,
  panelBlackList: {
    keyword: '',
    type: '',
    status: '',
    dateFrom: '',
    dateTo: '',
    page: 1,
    size: 50,
  }
}
