import { setCookie } from '@/helpers/cookie'

export default {
  MUT_STATE: (state:any, data:any) => {
    for (const item in data) {
      state[item] = data[item]
    }
  },
  // MUT_LOADER: (state:any, data:any) => {
  //   state.loader = data[item]
  // },
  MUT_TOKEN: (state:any, data:any) => {
    state.token = data
  },
  MUT_PLATFORMS: (state:any, data:any) => {
    state.platforms = data
  },
  MUT_PLATFORM: (state:any, data:any) => {
    state.platform = data
    setCookie('platform', data)
  },
  MUT_USERNAME: (state:any, data:any) => {
    state.username = data
  },
  MUT_USERINFO: (state:any, data:any) => {
    // state.platforms = data.projects
    state.username = data.username
    state.roles = data.roles
  }
}
