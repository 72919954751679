export default {
  customerId: '',
  managers: [],
  username: '',
  platforms: [],
  platform: '',
  isLoader: false,
  token: '',
  roles: [],
  graph: [],
}
