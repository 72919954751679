import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './lang/i18n'
import ElementPlus from 'element-plus'
import { money } from './helpers/prototype'

const app = createApp(App)
app.config.globalProperties.$money = money

app.use(ElementPlus)

app.use(i18n).use(store).use(router).mount('#app')
