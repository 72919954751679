<template>
  <div class="admin-flex">
    <layout-page>
      <AdminBar class="admin-bar"/>
      <router-view/>
    </layout-page>
  </div>
</template>

<script>
import AdminBar from '@/components/Admin/AdminBar.vue'
import LayoutPage from '@/shared/Layout/LayoutPage.vue'

export default {
  name: 'AdminComponent',
  components: {
    LayoutPage,
    AdminBar,
  }
}
</script>

<style lang="scss">
.admin-flex {
  //display: flex;
  //flex-direction: column;
  //justify-content: space-between;
  width: 100%;
  height: 100%;
}
.admin-bar {
  margin-bottom: 1rem;
}
@media (max-width: 900px) {
  .admin-bar {
    margin-bottom: 0;
  }
}
</style>
