import API from '@/api/API'
import { contactsZoho, supportZoho } from './template'
import { ElNotification } from 'element-plus'

export default {
  async CONTACT_HELPDESK ({ dispatch, commit }:any, id:any) {
    const customerId = id
    if (!customerId) return
    const method = API.contactHelpdesk(customerId)
    const response = await dispatch('getData', { method })
    const data = response?.data || []
    commit('SET_TICKET_STATE', { contact: contactsZoho(data) })
    return response
  },
  async REPLY_HELPDESK ({ dispatch, commit }:any) {
    const method = API.replyAddress
    const response = await dispatch('getData', { method })
    const data = response?.data || []
    commit('SET_TICKET_STATE', { addressReply: supportZoho(data) })
    return response
  },
  async TEAM_HELPDESK ({ dispatch, commit }:any) {
    const method = API.TeamHelpdesk
    const response = await dispatch('getData', { method })
    const team = response?.data || []
    commit('SET_TICKET_STATE', { team })
    return team
  },
  async TEMP_HELPDESK ({ dispatch, commit }:any) {
    const method = API.TempHelpdesk
    const response = await dispatch('getData', { method })
    const temp = response?.data || []
    commit('SET_TICKET_STATE', { temp })
    return response
  },
  async ADD_TICKET ({ dispatch }:any, params:any) {
    const method = API.addTicket
    const response = await dispatch('setData', { method, params })
    const ticketId = response?.data?.ticketId || ''
    ElNotification({
      type: 'success',
      message: 'SAVE ticketId: ' + ticketId,
      duration: 3000
    })
    return response
  },
  async TICKETS_LIST ({ commit, dispatch }:any, customerId:any) {
    const method = API.tickets(customerId)
    const response = await dispatch('getData', { method })
    const tickets = response?.data || []
    commit('SET_TICKET_STATE', { tickets })
    return tickets
  },
  async TICKET_ITEM ({ state, dispatch, commit }:any, ticketId:any) {
    const method = API.ticket(ticketId)
    const response = await dispatch('getData', { method }) || []
    const ticket = JSON.parse(JSON.stringify(state.ticket))
    ticket[ticketId] = response
    commit('SET_TICKET_STATE', { ticket })
    return response
  },
  async TICKET_ITEM_CONTENT ({ state, dispatch, commit, getters }:any, { ticketId, threadId }:any) {
    const method = API.ticketContent(ticketId, getters.platformName, threadId)
    const response = await dispatch('getData', { method })
    if (response?.id) {
      const ticketContent = JSON.parse(JSON.stringify(state.ticketContent))
      ticketContent[response.id] = response.content || ''
      commit('SET_TICKET_STATE', { ticketContent })
      return response?.content || ''
    }
  },
  async SEND_REPLY ({ dispatch }:any, { ticketId, params }:any) {
    const method = API.sendReply(ticketId)
    return await dispatch('setData', { method, params })
  },

  // async TEMPLATE_HELPDESK_ID ({ dispatch, getters }:any, tmpId:any) {
  //   const method = API.TempHelpdeskId(getters.platformName, tmpId)
  //   return await dispatch('getData', { method })
  // },
  MUT_TICKET_DESTROY ({ commit }:any) {
    commit('DESTROY_TICKET_STATE')
  }
}
