import { EKycStatus } from '@/dict/enum'

export default {
  panel: {
    type: '',
    status: 'ACTIVE',
    riskScore: [0, 0],
    kycStatus: EKycStatus.VERIFIED,
    country: '',
    dateFrom: '',
    dateTo: '',
    query: '',
    assignedTo: undefined,
    awaitingApproval: '',
    size: 20,
    page: 1,
  },
  clients: [],
  client: {},
  address: [],
  documents: [],
  limit: {},
  phones: [],
  email: [],
  representative: {},
  kycItem: {},
  representatives: [],
  total: 0,
}
