import { IDocumentData } from '@/dict/interface'
import { getCountry } from '@/dict/country'

export const documentsTemplate = (items: IDocumentData[] = []) => items.map((item) => ({
  id: item?.id,
  customerId: item?.customerId,
  name: item?.name || '',
  nameOnDocument: item?.nameOnDocument || '',
  status: item?.status || '',
  type: item?.type || '',
  confirmationType: item?.confirmationType || '',
  issuedAt: item?.issuedAt || '',
  expiresAt: item?.expiresAt || '',
  verifiedAt: item?.verifiedAt || '',
  createdAt: item?.createdAt || '',
  updatedAt: item?.updatedAt || '',
  customer: documentCustomer(item?.customer) || undefined,
}))
export const documentTemplate = (item: IDocumentData) => {
  return {
    id: item?.id,
    customerId: item?.customerId,
    name: item?.name || '',
    nameOnDocument: item?.nameOnDocument || '',
    status: item?.status || '',
    type: item?.type || '',
    confirmationType: item?.confirmationType || '',
    issuedAt: item?.issuedAt || '',
    expiresAt: item?.expiresAt || '',
    verifiedAt: item?.verifiedAt || '',
    createdAt: item?.createdAt || '',
    updatedAt: item?.updatedAt || '',
    customer: documentCustomer(item?.customer) || undefined,
    ocr: item?.ocr || undefined,
    metadata: item?.metadata || undefined,
    assignedTo: item?.assignedTo || undefined
  }
}

export const documentCustomer = (item:any) => {
  return {
    id: item?.id,
    name: item?.name || '',
    username: item?.username || '',
    birthdate: item?.birthdate || '',
    residenceCountry: getCountry(item?.residenceCountry) || '',
    status: item?.status || '',
    type: item?.type || '',
    // citizenshipCountry: getCountry(item?.citizenshipCountry) || '',
    // taxResidenceCountry: getCountry(item?.taxResidenceCountry) || '',
    // riskScore: item?.riskScore || 0,
    // kycStatus: item?.kycStatus || '',
    // taxIdentificationNumber: item?.taxIdentificationNumber || '',
    // businessTaxNumber: item?.businessTaxNumber || '',
    // submittedAt: item?.submittedAt || '',
    // verifiedAt: item?.verifiedAt || '',
    // createdAt: item?.createdAt || '',
    // updatedAt: item?.updatedAt || '',
    // identityConfirmed: item?.identityConfirmed || '',
  }
}
