import http from '@/api/http'
import { AxiosResponse } from 'axios'
import captureError from '@/api/captureError'
import { deleteCookie, getCookie, setCookie } from '@/helpers/cookie'
import { ElNotification } from 'element-plus'
import controllerResponse from '@/api/controllerResponse'
import API from '@/api/API'
import { graphTemplates } from '@/store/modules/global/templates'
import { Project } from '@/api/project'

/**
 * @description перед каждым запросом проверяет и добавляет в заголовок токен
 */

export default {
  async LOGIN ({ dispatch }:any) {
    const method = API.auth
    const response = await dispatch('HTTP', { method, loader: true })
    const redirectUrl = response?.data?.redirect_url || ''
    if (redirectUrl) window.location.replace(redirectUrl)
    return response
  },
  async LOGIN_PASS (store:any, data:any) {
    const method = API.authLogin
    const response = await http.post(method, data)
    if (response.status === 200) {
      const token = response?.data?.data?.token || ''
      setCookie('token', token)
      window.location.replace('/admin/paxum/clients')
    }
  },
  async LOGOUT ({ dispatch }:any) {
    const token = getCookie('token') || ''
    if (!token) {
      window.location.replace('/')
      return
    }
    const method = API.logout
    const response = await dispatch('getData', { method, loader: true })
    const redirectUrl = response?.data?.redirect_url || ''
    if (redirectUrl) {
      deleteCookie('token')
      deleteCookie('patch')
      window.location.replace(redirectUrl)
    }
    deleteCookie('token')
    deleteCookie('patch')
    // delete http.defaults.headers.common['X-API-TOKEN']
    // delete http.defaults.headers.common['X-PROJECT']
    // deleteCookie('PHPSESSID')
    // deleteCookie('version')
    // localStorage.removeItem('vuex')
    return true
  },
  async GET_TOKEN ({ dispatch, commit }:any, code: string) {
    const method = API.login(code)
    const response = await dispatch('HTTP', { method, loader: true })
    const token = response?.data?.token || ''
    if (token) {
      setCookie('token', token)
      commit('MUT_TOKEN')
      dispatch('USER_INFO')
      return token
    }
    dispatch('LOGIN')
    return response
  },
  async USER_INFO ({ dispatch, commit, getters }:any) {
    const projectSave = getters.GET_PLATFORM || ''
    const method = API.userInfo
    const response = await dispatch('getData', { method, loader: true })
    const data = response?.data || ''
    if (data) {
      const projects = data?.projects
      if (!Array.isArray(projects) && !projects?.[0]) return false
      commit('MUT_PLATFORMS', projects)
      commit('MUT_USERINFO', data)
      const platform = projects.includes(projectSave) ? projectSave : projects[0]
      dispatch('SET_PLATFORM', platform)
      if (!projects.includes(projectSave)) {
        window.location.replace(`/admin/${platform}/clients`)
      }
      return platform
    }
    return false
  },
  SET_PLATFORM ({ commit }:any, data: string) {
    commit('MUT_PLATFORM', data)
    return true
  },
  SET_PLATFORMS ({ commit }:any, data: string) {
    commit('MUT_PLATFORMS', data)
    return true
  },
  async MANAGERS_LIST ({ state, dispatch, commit }:any, term:string) {
    if (term && state.managers.length) return state.managers
    const query = term ? '?query=' + term : ''
    const method = API.managers(query)
    const response = await dispatch('getData', { method })
    const managers = response?.data || {}
    commit('MUT_STATE', { managers })
    return response
  },
  //
  /**
   * @description Global Actions
   * @param store
   * @param data
   * @param data.method
   * @param data.params
   * @param data.loader - указывает включать или не включать лоадер при работе запроса
   */
  getData: ({ commit }:any, data:any) => {
    if (!data.loader) commit('MUT_STATE', { isLoader: true })
    const body = {
      ...data,
      method: data.method,
      params: data.params,
    }

    return http
      .get(data.method, body)
      .then((res:AxiosResponse) => controllerResponse(data.method, res) || {})
      .catch((error:any) => {
        captureError(data.method, error, 'error')
        return false
      })
      .finally(() => {
        if (!data.loader) commit('MUT_STATE', { isLoader: false })
      })
  },
  setData: ({ commit }:any, data:any) => {
    const isNotify = (data.config && data.config.isNotify) || false
    commit('MUT_STATE', { isLoader: true })
    return http
      .post(data.method, data.params)
      .then((res:AxiosResponse) => {
        if (!isNotify) {
          ElNotification({
            type: 'success',
            message: 'SAVE',
            duration: 2000
          })
        }
        return controllerResponse(data.method, res)
      })
      .catch((error:any) => captureError(data.method, error, 'error'))
      .finally(() => {
        commit('MUT_STATE', { isLoader: false })
      })
  },
  setFile: ({ commit }: any, data: any) => {
    const formData = new FormData()
    const headers = { 'content-type': 'multipart/form-data' }
    formData.append('form', data.params)
    commit('MUT_STATE', { isLoader: true })
    return http
      .post(data.method, formData, { headers })
      .then((res:AxiosResponse) => {
        ElNotification({
          type: 'success',
          message: 'SAVE',
          duration: 2000
        })
        return controllerResponse(data.method, res)
      })
      .catch((error:any) => captureError(data.method, error, 'error'))
      .finally(() => {
        commit('MUT_STATE', { isLoader: false })
      })
  },
  setDelete: ({ commit }: any, data: any) => {
    commit('MUT_STATE', { isLoader: true })
    return http
      .delete(data.method, data.params)
      .then((res:AxiosResponse) => {
        ElNotification({
          type: 'success',
          message: 'Delete',
          duration: 1000
        })
        return controllerResponse(data.method, res)
      })
      .catch((error:any) => {
        captureError(data.method, error, 'error')
        return false
      })
      .finally(() => {
        commit('MUT_STATE', { isLoader: false })
      })
  },
  patchData: ({ commit }: any, data: any) => {
    commit('MUT_STATE', { isLoader: true })
    return http
      .patch(data.method, data.params)
      .then((res:AxiosResponse) => {
        ElNotification({
          type: 'success',
          message: 'SAVE',
          duration: 2000
        })
        return controllerResponse(data.method, res)
      })
      .catch((error:any) => {
        captureError(data.method, error, 'error')
        return false
      })
      .finally(() => {
        commit('MUT_STATE', { isLoader: false })
      })
  },
  putData: ({ commit }: any, data: any) => {
    commit('MUT_STATE', { isLoader: true })
    return http
      .put(data.method, data.params)
      .then((res:AxiosResponse) => {
        ElNotification({
          type: 'success',
          message: 'SAVE',
          duration: 2000
        })
        return controllerResponse(data.method, res)
      })
      .catch((error:any) => {
        captureError(data.method, error, 'error')
        return false
      })
      .finally(() => {
        commit('MUT_STATE', { isLoader: false })
      })
  },
  HTTP: ({ commit }:any, data:any) => {
    if (!data.loader) commit('MUT_STATE', { isLoader: true })
    const body = {
      method: data.method,
      params: data.params
    }
    return http
      .get(data.method, body)
      .then((res:AxiosResponse) => controllerResponse(data.method, res) || {})
      .catch((error:any) => {
        captureError(data.method, error, 'error')
        return false
      })
      .finally(() => {
        if (!data.loader) commit('MUT_STATE', { isLoader: false })
      })
  },

  /**
   * @description  FETCH GET экспеерментальная сторонняя апи
   */
  async FETCH_API_GET ({ getters }: any, { url, method = 'POST', body }: any) {
    let countRequest = 0
    const platform = getters.GET_PLATFORM || ''
    const getData: any = async () => {
      const config: any = {
        method,
        headers: {
          access_token: process.env.VUE_APP_API_PAXUM_TOKEN
        }
      }
      if (body) config.body = JSON.stringify(body)
      const link = `${Project.get(platform)?.urlInfo}${url}`
      return await fetch(link, config)
        .then((request: Response) => {
          if (request.status === 500 && countRequest === 0) {
            countRequest += 1
            return getData()
          }
          return request.json()
        }).catch(() => {
          return []
        })
    }
    return await getData()
  },
  async QUERY_GRAPH ({ dispatch }:any, val:any) {
    const query = new URLSearchParams(val).toString()
    const method = API.queryGraph(query)
    const response = await dispatch('getData', { method })
    const data = response?.data || []
    return graphTemplates(data, val.customerId)
  },
  async QUERY_GRAPH_TRANSFER ({ dispatch }:any, val:any) {
    const query = new URLSearchParams(val).toString()
    const method = API.queryGraphTransfer(query)
    const response = await dispatch('getData', { method })
    return response?.data || []
  },

}
