import { EUserRoles } from '@/dict/enum'

export default {
  isLoader: ({ isLoader }:any) => !!isLoader,
  GET_PLATFORM: ({ platform }:any) => platform || '',
  GET_PLATFORMS: ({ platforms }:any) => platforms || [],
  GET_MANAGERS: ({ managers }:any) => managers || [],
  GET_USERNAME: ({ username }:any) => username,
  /**
   * @description для пермишен, роутов
   * @param username
   * @constructor
   */
  GET_ROLE: ({ roles }:any) => {
    // const roles = [EUserRoles.CUSTOMER_ADMIN] // test dev
    const names = (() => {
      if (roles.includes(EUserRoles.CUSTOMER_READ)) return 'User'
      if (roles.includes(EUserRoles.DOCUMENT_READ)) return 'User'
      if (roles.includes(EUserRoles.CUSTOMER_ADMIN)) return 'Edit'
      if (roles.includes(EUserRoles.DOCUMENT_ADMIN)) return 'Edit'
      return 'Admin'
    })()
    return {
      isAdmin: roles.includes(EUserRoles.ADMIN),
      isCustomer: roles.includes(EUserRoles.ADMIN) || roles.includes(EUserRoles.CUSTOMER_ADMIN) || roles.includes(EUserRoles.CUSTOMER_READ),
      isCustomerAdmin: roles.includes(EUserRoles.ADMIN) || roles.includes(EUserRoles.CUSTOMER_ADMIN),
      isCustomerRead: roles.includes(EUserRoles.CUSTOMER_READ),
      isDocument: roles.includes(EUserRoles.ADMIN) || roles.includes(EUserRoles.DOCUMENT_ADMIN) || roles.includes(EUserRoles.DOCUMENT_READ),
      isDocumentAdmin: roles.includes(EUserRoles.ADMIN) || roles.includes(EUserRoles.DOCUMENT_ADMIN),
      isDocumentRead: roles.includes(EUserRoles.DOCUMENT_READ),
      type: names,
    }
  },
  GET_GRAPH: ({ graph }:any) => graph,
}
