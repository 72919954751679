import API from '@/api/API'

export default {
  async BLACK_LIST ({ dispatch, commit, state }:any) {
    const params = state.panelBlackList
    let query = new URLSearchParams(params).toString()
    if (query) query = '?' + query
    const method = API.blacklist(query)
    const response = await dispatch('getData', { method })
    const blackList = response?.data || []
    const blackListTotal = response?.meta?.total || 0
    commit('SET_SETTINGS_STATE', { blackList, blackListTotal })
    return blackList
  },
  async BLACK_LIST_ADD ({ dispatch }:any, params: any) {
    const method = API.blacklistAdd
    const blackList = await dispatch('setData', { method, params })
    dispatch('BLACK_LIST')
    return blackList
  },
  async BLACK_LIST_EDIT ({ dispatch }:any, { status, id }: any) {
    const method = API.blacklistEdit(id)
    const params = { status }
    const blackList = await dispatch('putData', { method, params })
    dispatch('BLACK_LIST')
    return blackList
  },
  CLEAR_PANEL_BLACK_LIST ({ commit }: any) {
    const panel = {
      keyword: '',
      type: '',
      status: '',
      dateFrom: '',
      dateTo: '',
      page: 1,
      size: 50,
    }
    commit('MUT_PANEL_BLACK_LIST', panel)
  },
  async EDIT_PERMISSION_MANAGER ({ dispatch }:any, values: any) {
    const method = API.manager(values.id)
    const params = { projects: values.projects, roles: values.roles }
    const blackList = await dispatch('setData', { method, params })
    dispatch('MANAGERS_LIST')
    return blackList
  },
}
