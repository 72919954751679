export const contactsZoho = (items:[]) => {
  return items.map((item:any) => ({
    id: item?.id,
    name: item?.name || item?.firstName + ' ' + item?.lastName,
    email: item?.email,
    firstName: item?.firstName,
    lastName: item?.lastName,
    // ownerId: item?.ownerId,
    // photoUrl: item?.photoUrl,
    // createdAt: item?.createdAt,
  }))
}
export const supportZoho = (items:[]) => {
  return items.map((item:any) => ({
    id: item?.id,
    name: item?.name,
    email: item?.address,
    departmentId: item?.departmentId,
    // "isVerified": true
  }))
}
