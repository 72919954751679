export default {
  GET_DOCUMENTS__PANEL: (state: any) => {
    const { status, country, dateFrom, dateTo, query, size, page, assignedTo } = state.panel
    const PAGE = page ? `page=${page}` : ''
    const PAGE_SIZE = size ? `&size=${size}` : ''
    const STATUS = status ? `&status=${status}` : ''
    const SEARCH = query ? `&query=${query}` : ''
    const DATE_FROM = dateFrom ? `&dateFrom=${dateFrom}` : ''
    const DATE_TO = dateTo ? `&dateTo=${dateTo}` : ''
    const COUNTRY = country ? `&country=${country}` : ''
    const ASSIGNED = assignedTo ? `&assignedTo=${assignedTo}` : ''
    return `${PAGE}${PAGE_SIZE}${STATUS}${SEARCH}${DATE_FROM}${DATE_TO}${COUNTRY}${ASSIGNED}&include=customer`
  },
  GET_DOCUMENT: ({ document }: any) => (document),
  GET_DOCUMENTS: ({ documents }: any) => (documents),
  GET_DOCUMENTS_PANEL: ({ panel }:any) => (panel),
  GET_DOCUMENTS_TOTAL: ({ total }:any) => (total),
  GET_DOCUMENTS_LAST: ({ documentsLast }:any) => (documentsLast),
  GET_DOCUMENTS_LAST_APPROVE: ({ documentsLastApprove }:any) => (documentsLastApprove),
  GET_DOCUMENTS_LAST_REJECT: ({ documentsLastReject }:any) => (documentsLastReject),
}
